@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./App.css);
@import url(./index.css);
@import url(./mediaquerry/containerquerry.css);
@import url(./animations/animations.css);
@import url(./animations/card.css);
@import url(./content-styles.css);


input[type="date"]::-webkit-input-placeholder{
  visibility: hidden !important;
}



@layer base {
    ul{
      list-style:disc;
      margin: 0;
      padding: 0 12px;
    }
    ol{
      list-style:decimaml;
      margin: 0;
      padding: 0;
    }
    h1 {
      font-size: 44px;
      font-weight: 600;

    }
    h2 {
      font-size:40px;
      font-weight: 500;
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
    }
    h4 {
        font-size: 22px;
        font-weight: 400;
    }
    h5 {
        font-size: 20px;
        font-weight: 500;
    }
    h6 {
        font-size: 18px;
        font-weight: 400;
    }
    p {
        font-size: 16px;
        font-weight: 400;
    }
    label {
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
    }
    button {
      /* filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)); */
      transition: 0.3s;
    }
    /* select {
      font-family: 'Roboto', serif;
    } */
    /* button:hover{
      filter: drop-shadow(0 0 #0000);
    } */
    .robotoSlab {
      font-family: 'Roboto Slab', serif;
    }
    .recursive {
      font-family: 'recursive', serif;
    }
    .mdpCondition li:before{
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #E0E0E0; /* Change the color */
      font-size: 10px;
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: 6px; /* Also needed for space (tweak if needed) */
    }

    /* **************************Rendre vignette visible****************************** */
    .switch {
      --button-width: 3.5em;
      --button-height: 2em;
      --toggle-diameter: 1.5em;
      --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
      --toggle-shadow-offset: 10px;
      --toggle-wider: 3em;
      --color-grey: #cccccc;
      --color-green: #BDD8EC;
     }

     .slider {
      display: inline-block;
      width: var(--button-width);
      height: var(--button-height);
      background-color: var(--color-grey);
      border-radius: calc(var(--button-height) / 2);
      position: relative;
      transition: 0.3s all ease-in-out;
     }

     .slider::after {
      content: "";
      display: inline-block;
      width: var(--toggle-diameter);
      height: var(--toggle-diameter);
      background-color: #fff;
      border-radius: calc(var(--toggle-diameter) / 2);
      position: absolute;
      top: var(--button-toggle-offset);
      transform: translateX(var(--button-toggle-offset));
      box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
      transition: 0.3s all ease-in-out;
     }

     .switch input[type="checkbox"]:checked + .slider {
      background-color: var(--color-green);
     }

     .switch input[type="checkbox"]:checked + .slider::after {
      transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
      box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
     }

     .switch input[type="checkbox"] {
      display: none;
     }

     .switch input[type="checkbox"]:active + .slider::after {
      width: var(--toggle-wider);
     }

     .switch input[type="checkbox"]:checked:active + .slider::after {
      transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
     }

  }
  /* Styles pour l'infobulle */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Styles pour le texte de l'infobulle */
.tooltip .tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: #263238;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.break-word{
  word-break: break-word;
}

.gradient-blue{
  background-image: linear-gradient(to bottom, #5b9ecf, #7ab0da, #96c1e5, #b2d4f1, #cde6fc);}
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900;1000&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');