 /* ckeditor.css */

/* Style pour les paragraphes */
/* p {
    margin-bottom: 10px;
  } */
  
  /* Style pour le texte en italique */
  /* em {
    font-style: italic;
  } */

  
  /* Style pour les listes */
  /* ul,
  ol {
    margin-bottom: 10px;
  } */

  /* .ck-editor__editable_inline {
    min-height: 160px;
    max-height: 262px;
  } */

  /* Styles pour la barre d'outils */
.ck.ck-toolbar {
  background-color: #EFF5FA;
  border: 1px solid #BDD8EC;
  padding: 8px;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners{
  border-radius: 10px 10px 0px 0px;

}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
  border: 1px solid #BDD8EC;
  border-radius: 0px 0px 10px 10px;

}
/* Styles pour les boutons de la barre d'outils */
/* .ck.ck-toolbar .ck-button {
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
} */

/* .ck.ck-toolbar .ck-button:hover {
  background-color: #f2f2f2;
} */

/* Styles pour la zone de contenu */
.ck.ck-editor__editable {
  border: 1px solid #EFF5FA;
  height: 300px;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
}

/* Ajoutez d'autres styles personnalisés selon vos besoins */