.card .availability{
  left: 0.75rem;
  transition: 1s;
}
.card:hover .availability{
    display: none;
}

.card .makefavoris{
  background: rgba(91, 158, 207, 0.3);
  backdrop-filter: blur(7.5px);
  bottom: 6px;
  transition: 0.5s;
}
.card:hover .makefavoris{
  background: none;
  display: none;
  backdrop-filter: blur(0px);
  bottom: -4rem;
  transition: 0.5s;
}

.card .middle-part{
  top: 165px;
  transition: 1s;
}
.card:hover .middle-part{
  top: 0px;
  transition: 1s;
}

.card .Anonymous{
  display: none;
  padding-top: 0px;
  height: 0px;
  overflow: hidden;
  transition: 1s;
}
.card:hover .Anonymous{
  display: block;
     position: absolute;
    height: 384px;
    transition: 1s;
    top: 10px;
    z-index: 2;
    left: -9px;

}
.card:hover .card-titre{
  padding: 0 0;
  transition: 1s;
}

.card .moretag{
  padding: 0px;
  height: 0px;
  overflow: hidden;
  transition: 1s;
}
.card:hover .moretag{
  display: inline-block;
  padding: 0.5rem;
  height: 22px;
  transition: 1s;
}

.card .bottom-part{
  bottom: -800px;
  transition: 1s;
}
.card:hover .bottom-part{
  display: block;
  bottom: 0;
  transition: 1s;
}

.card .top-part{
  bottom: -800px;
  transition: 1s;
}
.card:hover .top-part{
  display: block;
  bottom: 160px;
  transition: 1s;
}

.card .favoris-like{
  bottom: -800px;
  transition: 1s;
}

.card:hover .favoris-like{
  bottom: 22.9rem;
  margin-left: 5px;
  transition: 1s;
}

.card .mobility{
  bottom: -800px;
  transition: 1s;
}

.card:hover .mobility{
      position: relative;
    max-width: 122px;
    right: -93px;
  bottom: 37.5rem;
  transition: 1s;
}

.card .title{
  bottom: auto;
transition: 1s;
}

.card:hover .title{
     position: relative;
    z-index: 2;
    top: -9rem;
    bottom: -200px;
    transition: 1s;
}

.card .intervention
{
  bottom: auto;
  transition: 1s;
}

.card:hover .intervention
{
    position: relative;
    z-index: 2;
    top: -34.5rem;
    transition: 1s;
}

