.radio-button {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #5B9ECF;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #5B9ECF;
}

.checked2 {
    background-color: #5B9ECF;
    color: #FFF;
}

.radio-button input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+.radio-button {
    /* Styles à appliquer à .radio-button lorsque la case est cochée */
    /* Par exemple, vous pouvez changer la couleur de fond ou le style du bouton */
    background-color: #5B9ECF;
    color: #fff;
}

.radio-label {
    font-size: 16px;
    font-weight: bold;
}

.radio-button:hover {
    background-color: #5B9ECF;
    color: #FFF;
}