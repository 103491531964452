::-webkit-scrollbar {width: 6px; }
::-webkit-scrollbar-track {background: #ffffff60;}
::-webkit-scrollbar-thumb {border-radius: 100px;
background: #5B9ECF80;}
html{
   scrollbar-width: thin;
   scrollbar-color: #5B9ECF80 white;
}
* {
  font-family: 'Roboto';
}
body{
    font-family: 'Roboto';
    color: #263238;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.slick-dots button::before{
    background-color: #5B9ECF;
    border-radius: 50%;
}

li.slick-active > button::before {
    color: #5b9fcf00 !important;
}

li > button::before {
    color: #5b9fcf00 !important;
    height: 12px !important;
    width: 12px !important;
  }

  /* Enlève les flèche des input number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number]{
    -moz-appearance: textfield;
}

  input[type=range] {
    /* height: 29px; */
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: #26323800;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 4px #A6A6A6;
    background: #ffffff;
    border-radius: 20px;
    /* border: 0px solid #F27B7F; */
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 0px #A6A6A6;
    /* border: 2px solid #F27B7F; */
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #A6A6A6;
    background: #ffffff;
    border-radius: 20px;
    border: 0px solid #F27B7F;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #A6A6A6;
    border: 2px solid #BDD8EC;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #BDD8EC;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #FF96AB;
    border: 0px solid #F27B7F;
    border-radius: 40px;
    box-shadow: 0px 0px 4px #A6A6A6;
  }
  input[type=range]::-ms-fill-upper {
    background: #fcfcfc;
    border: 0px solid #ffffff;
    border-radius: 40px;
    box-shadow: 0px 0px 4px #A6A6A6;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 0px #A6A6A6;
    border: 2px solid #BDD8EC;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background: #BDD8EC;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
  }
  input[type="range"]::-moz-range-progress {
    background-color:#BDD8EC;
    height: 10px;
    border-radius: 20px;
  }
  


  