.checkbox-button {
    display: inline-block;
    /* padding: 5px 10px; */
    border: 1px solid #5B9ECF;
    /* border-radius: 5px; */
    cursor: pointer;
    text-align: center;
    color: #5B9ECF;
}

.checked {
    background-color: #5B9ECF;
    color: #FFF;
}

.checkbox-button:hover {
    background-color: #5B9ECF;
    color: #FFF;
}
.hidde-checkbox input[type="checkbox"] {
    display: none;
}



input[type="radio"]:checked+.checkbox-button {
    background-color: #007bff;
    color: #fff;
}

.checkbox-label {
    font-weight: bold;
}


.hidde-radio {
    display: inline-block;
    /* padding: 5px 10px; */
    border: 1px solid #5B9ECF;
    /* border-radius: 5px; */
    cursor: pointer;
    text-align: center;
    color: #5B9ECF;
}
.hidde-radio+label:hover {
    background-color: #5B9ECF;
    color: #FFF;
}

.hidde-radio + label {
    border: 1px solid #5B9ECF;
}
input[type="radio"]:checked.hidde-radio + label  {
    background-color: #007bff!important;
    color: #fff;
}

input[type="radio"] {
    display: none;
  }

