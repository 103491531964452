#Line1 {
  height: 0px;
}

#Line1.active {
  height: 135px;
  transition: height 2.8s;
}

#Line2 {
  height: 0px;
}

#Line2.active {
  height: 205px;
  transition: height 2.8s;
}

#Line3 {
  height: 0px;
}

#Line3.active {
  height: 130px;
  transition: height 2.8s;
}

@keyframes fadeFromTop {
    0% {
      opacity: 0;
    } 100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes fadeFromLeft {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
  }
   @keyframes fadeToLeft {
    0%{
        opacity: 1;
        left:2rem;
    }
    100%{
        opacity: 0;
        left:-50%;
        transform: translateX(-0px);
    }
  }

