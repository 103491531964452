
@media screen and (min-width: 1024px) and (max-width: 1090px) {

    .container{
        padding: 0px 14px 0px 14px;
        }
    /* .bg-center-right{
        background-position: center right;
    } */
    
}
@media screen and (max-width: 1000px) {
    .containerxl{
        max-width: 95%;
        /* padding: 0px 2%; */
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 1000px) {
    .containerxl{
        max-width: 90%;
        /* padding: 0px 10px; */
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 2000px) {
    .containerxl{
        /* max-width: 2500px; */
        /* padding: 0px 100px; */
        margin-left: auto;
        margin-right: auto;
    }
    .containerxlsmaller{
        max-width: 2000px;
        /* padding: 0px 100px; */
        margin-left: auto;
        margin-right: auto;
    }
    
    .containerfix{
        width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
}
