@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('./mediaquerry/containerquerry.css');
@import url("./animations/animations.css");
@import url("./animations/card.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}
#form_opportunite_localisation label
{
  margin-right: 1.5rem;
}
#form_opportunite_localisation input
{
  margin-right: 0.5rem;
}

#form_opportunite_work_experience label
{
  margin-right: 1.5rem;
}
#form_opportunite_work_experience input
{
  margin-right: 0.5rem;
}

/* KEY FRAME ANIMATION CSSS*/
.on{
    display: block;
}
@media (min-width: 1024px) {
    .on{
        display: inline-block;
    }
}
.current{
   background: rgba(131, 187, 226, 0.4);
   border-radius: 9999px;
   display: inline-block;
   line-height: 40px;
   margin-right: 5px;
   text-align: center;
   width: 40px;
}
.fallTop
{

    transform: translateY(-300px);
    animation: fadeFromTop 2s ease forwards;
}
.fadeFromLeft{
    bottom:2rem;
    left:2rem;
    transform: translateX(-300px);
    animation: fadeFromLeft 2s ease forwards;
}
.fadeToLeft{
    bottom:2rem;
    animation: fadeToLeft 2s ease forwards;
}
.fallLeftBlueBar{
    transform: translateX(-150px);
    animation: fadeFromLeft 4s ease forwards;
}
.fallLeftWhiteBar{
    transform: translateX(-600px);
    animation: fadeFromLeft 2s ease forwards;
}
.animation-box {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 1s;
}
.animation-content {
  align-self: end;
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
}
/* KEY FRAME ANIMATION CSSS END*/

.letter-space-3{
     word-spacing: 3px;
}
.box-shadow{
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
}
.box-shadow-formation{
    box-shadow:4px 4px 4px rgba(0, 0, 0, 0.25)
}
.text-gray-400	{color: rgb(156 163 175);}
.font-poppins{
    font-family: 'Poppins', sans-serif;
}
.font-merriweather{
    font-family: 'Merriweather', serif;
}

.container{
    margin: auto;
}
.bg-hero{
    background-size: cover;
    background-image: url("../../public/img/hero.jpeg");
    background-position: center top;
}
.text-hero{
    font-size: 64px;
}

.title-h1{
    font-size: 24px;
}


.hr-blue-hero{
    position: relative;
    margin: 0px 850px 0px 0px;
    border-top-color: #5B9ECF;
    border-top-width: 7px;
}
.hr-white-hero{
    margin: -4px 100px 0px 0px;
    border-top-color: rgba(255,255,255,0.45);;
    border-top-width: 1px;
}

.hr-blue{
    width: 100%;
    border-top-color: #5B9ECF;
    border-top-width: 6px;
}
.hr-white{

    border-top-color: #FFFF;
    border-top-width: 2px;
}
.hr-grey{
    border-top-color: #9393937a;
    border-top-width: 1px;
    margin: -3px 100px 0px 0px;

}

.hr-blue-buissnes{
    position: relative;
    margin: 20px 600px 0px 0px;
    border-top-color: #5B9ECF;
    border-top-width: 5px;
}

.bg-img-quisommesnous{
    background-position: center;
    padding: 7%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg,rgba(3,15,48,0.2) 0%,rgba(3,15,48,0.96) 100%),url(https://myfinplace.com/wp-content/uploads/2022/01/Busy-employer-Photo_bleu_banière_4-3.png);
}

.purpose-1{
    margin-top: -1vw!important;
}
.purpose-2{
    margin-top: -2vw!important;
}
.purpose-3{
    margin-top: -3vw!important;
}
.purpose-4{
    margin-top: -4vw!important;
}
.purpose-5{
    margin-top: -5vw!important;
}

.bg-img-accueil-QuelqueChiffre{
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    background-image: linear-gradient(180deg,rgba(3,15,48,0.96) 0%,rgba(3,15,48,0.2) 100%), url(../../public/img/FondChiffre.png);
}

.bandeau-contacternous{
    background-image: linear-gradient(135deg,#3b91ce 70%,rgba(23,0,189,0) 70%);
    padding-top: 80px;
    padding-right: 40px;
    padding-bottom: 300px;
    padding-left: 40px;
    color:white;
}
.Inscription-image-1{
    background-image: linear-gradient(0deg,rgba(34,31,60,0.9) 0%,rgba(34,31,60,0.5) 43%,rgba(34,31,60,0.4) 52%,rgba(255,255,255,0) 100%),url(../../public/img/Inscription-image-1.png);
    background-color: initial;
    background-repeat:no-repeat;
    background-size: cover;
    padding-top: 310px;
    padding-right: 39px;
    padding-bottom: 39px;
    padding-left: 39px;
}
.Inscription-image-2{
    background-image: linear-gradient(180deg,rgba(34,31,60,0.3) 0%,rgba(34,31,60,0.3) 100%),url(../../public/img/Inscription-image-2.png);
    background-repeat:no-repeat;
    background-size: cover;
    padding-top: 142px !important;
    padding-bottom: 142px !important;
}

.Section-central-search-opportunity{
    width: calc(100% - 460px);
}
@media screen and (max-width: 1024px) {
  .Section-central-search-opportunity{
    width:98%;
    }
}

.custom-toggle-label{
    display: flex;
    align-items: center;
    padding: 4px;
    width: calc(60px + 2*  4px);
    height: calc(30px + 2*  4px);
    position: relative;
    border-radius: 30px;
    background-color: #adadad;
}
.custom-toggle-label > span{
        width: 30px;
        height: 30px;
        position: absolute;
        left: 4px;
        border-radius: 30px;
        background-color: white;
        transition: left 0.3s ease ;
}

.custom-toggle-checkbox:checked + label{
        background-color: #3b91ce;
}


.custom-toggle-checkbox:checked + label > span
{
    left: calc(100% - 34px);
}
.custom-toggle-checkbox{
    display: none;
}
.smaller-scroll-bar{
    scrollbar-width: thin;
    scrollbar-color: #5A9ECF #EAEAEA;
}

.UserMenuTop:hover div.UserMenuTopBorder {
    border-bottom: 2px solid red;
    width: 20px;
    margin: auto;
    margin-top: 5px;
}
div.ActifUserMenuTopBorder{
    border-bottom: 2px solid red;
    width: 20px;
    margin: auto;
    margin-top: 5px;
}


button,button[type=button], input[type=submit], input[type=reset] {
    background-color: none ;
}

.shadow-Profil{
    box-shadow: 4px 4px 2px rgba(29, 107, 163, 0.2);
}

.shadow-Profil-clearer{
    box-shadow: 4px 4px 4px rgba(183, 218, 243, 0.3);
}

.shadow-fiche-profile{
    box-shadow: 4px 4px 2px rgba(131, 187, 226, 0.4);
}

.shadow-profil-icon{
    box-shadow: 2px 2px 2px rgba(29, 107, 163, 0.4);
}

.text-shadow{
    text-shadow: #030F30 0.1em 0.1em 0.2em
}